import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const TermsPage = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <h1 className="text-4xl font-semibold text-center pb-10">
      Terms and Conditions
    </h1>
    <div className="flex flex-col justify-center items-center px-10">
      <p className="max-w-xl pb-5">
        These terms of service govern your access to and use of the Simmr
        website and application. Please read these terms carefully, and contact
        us if you have any questions at {' '}
        <a className="link" href="mailto:support@simmr.us">
          support@simmr.us
        </a>
        . By accessing or using our services and products, you agree to be bound
        by these terms and by our privacy policy and community guidelines. By
        adhering to these terms and conditions, you will receive a limited,
        non-exclusive, non-transferable, and revocable license to use our
        service.
      </p>
      <ol className="max-w-xl">
        <li className="pb-5">
          <span className="font-semibold">I. Using Simmr: </span> Any use or
          access of Simmr by anyone under the age of 13 is prohibited. Using
          Simmr may include downloading software to your computer, phone,
          tablet, or other device. You agree to receive automatic updates to the
          software and these terms will apply to any updates. Users of Simmr
          agree to abide by the Community Guidelines, or risk termination of
          their account.
        </li>
        <li className="pb-5">
          <span className="font-semibold">II. User Content: </span>
          Anything that is uploaded to or entered in your Simmr account, or
          otherwise made available on your Simmr account by the user is referred
          to as “user content.” You retain all rights in, and are solely
          responsible for, the user content you make available on Simmr. You may
          decide to share your user content with other users, but will retain
          your ownership of that content. User content must abide by the
          Community Guidelines. Upon deletion of your account, your user content
          will be deleted from Simmr’s database and will not be recoverable.
          <ol className="pb-5 flex justify-center">
            <li className="max-w-lg">
              A. If you choose to submit feedback or comments you agree that we
              are free to use them without any restriction or compensation to
              you. By accepting your submission, Simmr does not waive any rights
              to use similar or related feedback previously known to Simmr, or
              developed by its employees, or obtained from sources other than
              you.
            </li>
          </ol>
        </li>
        <li className="pb-5">
          <span className="font-semibold">III. Security: </span> We care about
          the security of our users. We utilize the 256-bit Advanced Encryption
          Standard to encrypt all user data. However, while we work to protect
          the security of your content and account, it is not guaranteed that
          unauthorized parties will be unable to defeat our security measures.
          This is a possible risk when utilizing Simmr services.
        </li>
        <li className="pb-5">
          <span className="font-semibold">IV. Third Parties: </span> Simmr may
          contain links to third party sites and services. If you access any
          third party website, service, or content from Simmr, you do so at your
          own risk and you agree that Simmr has no liability arising from your
          use of or access to any third party website, service, or content.
        </li>
        <li className="pb-5">
          <span className="font-semibold">V. Payments and Cancellations: </span>
          You may purchase a Paid Subscription directly from Simmr by (1) paying
          a subscription fee in advance on a monthly basis or some other
          recurring interval disclosed to you prior to your purchase; or (2)
          Prepayment giving you access to the Simmr service for a specific time
          period (“Pre-Paid Period”) disclosed to you prior to your purchase.
          <ol className="pb-5 flex flex-col justify-center items-center">
            <li className="max-w-lg">
              A. Simmr may change the price for the Paid Subscriptions,
              including recurring subscription fees, the Pre-Paid Period (for
              periods not yet paid), from time to time and will communicate any
              price changes to you in advance and, if applicable, how to accept
              those changes. Price changes will take effect at the start of the
              next subscription period following the date of the price change.
              You accept the new price by continuing to use the Simmr service
              after the price change takes effect. If you do not agree with a
              price change, you have the right to reject the change by
              unsubscribing from the Paid Subscription prior to the price change
              going into effect.
            </li>
            <li className="max-w-lg">
              B. Unless your Paid Subscription has been purchased for a Pre-Paid
              period, your payment to Simmr will automatically renew at the end
              of the applicable subscription period, unless you cancel your Paid
              Subscription before the end of the then-current subscription
              period. The cancellation will take effect the day after the last
              day of the current subscription period, and you will be downgraded
              to the Free Service. We do not provide refunds or credits for any
              partial subscription periods.
            </li>
          </ol>
        </li>
        <li className="pb-5">
          <span className="font-semibold">VI. Termination: </span>
          Simmr may terminate or suspend your right to access or use Simmr for
          any reason on appropriate notice. We may terminate or suspend your
          access immediately and without notice if we have good reason,
          including any violation of our Community Guidelines. In the event of
          your account being terminated, your user content will be deleted from
          Simmr’s database and will not be recoverable. If you had a Paid
          Subscription, you will cease to be charged. The terms and agreements
          will continue to apply to you until terminated by either you or Simmr.
          However, you acknowledge and agree that the perpetual license granted
          by you in relation to User Content, including feedback, is irrevocable
          and will therefore continue after expiry or termination of any of the
          agreements for any reason.
        </li>
        <li className="pb-5">
          <span className="font-semibold">VII. Disclaimers:</span>
          <ol className="pb-5 flex flex-col justify-center items-center">
            <li className="max-w-lg">
              A. Our Service and all content on Simmr is provided on an "as is"
              basis without warranty of any kind, whether expressed or implied.
              Simmr takes no responsibility and assumes no liability for any
              user content that you or any other person or third party provides
              or sends using our service. You understand and agree that you may
              be exposed to user content that is inaccurate, objectionable,
              inappropriate for children, or otherwise unsuited to your purpose.
            </li>
            <li className="max-w-lg">
              B. Simmr reserves the right, periodically and at any time, to
              modify or discontinue, temporarily or permanently, functions and
              features of the Simmr service, with advance notice where possible,
              all without liability to you, except where prohibited by law, for
              any interruption, modification, or discontinuation of the Simmr
              Service or any function or feature thereof. Notwithstanding the
              foregoing, if you have prepaid fees to Simmr for Paid
              Subscriptions that Simmr permanently discontinues prior to the end
              of the Pre-Paid Period, Simmr will refund you the prepaid fees for
              the Pre-Paid Period after such discontinuation. You understand,
              agree, and accept that Simmr will make reasonable efforts,
              although it has no obligation to maintain, support, upgrade, or
              update the Service, or to provide all or any specific content
              through the Service.
            </li>
          </ol>
        </li>
        <li className="pb-5">
          <span className="font-semibold">VIII. Limitation of Liability: </span>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, SIMMR SHALL NOT BE LIABLE FOR
          ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
          OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
          INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
          LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR INABILITY TO
          ACCESS OR USE THE SERVICE; (B) ANY CONDUCT OR CONTENT OF ANY THIRD
          PARTY ON THE SERVICE, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY,
          OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; OR (C)
          UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR
          CONTENT. IN NO EVENT SHALL SIMMR'S AGGREGATE LIABILITY FOR ALL CLAIMS
          RELATING TO THE SERVICE EXCEED ONE HUNDRED U.S. DOLLARS (U.S.
          $100.00).
        </li>
        <li className="pb-5">
          <span className="font-semibold">
            IX. Governing Law and Jurisdiction:
          </span>
          Any controversy or claim arising out of or relating to this contract,
          or the breach thereof, shall be settled by arbitration administered by
          the American Arbitration Association in accordance with its Commercial
          Arbitration Rules, and judgment on the award rendered by the
          arbitrator(s) may be entered in any court having jurisdiction thereof.
          If the arbitration ruling is for any reason found to be invalid, these
          Terms shall be governed by the laws of the State of Utah, without
          respect to its conflict of laws principles. The exclusive place of
          jurisdiction for all disputes arising from or in connection with this
          agreement is Salt Lake County, Utah, and our dispute will be
          determined under Utah law.
        </li>
        <li className="pb-5">
          <span className="font-semibold">X. General Terms:</span>
          <ol className="pb-5 flex flex-col justify-center items-center">
            <li className="max-w-lg">
              A. We reserve the right to update our Terms and Conditions at any
              time. We also reserve the right to determine the form of
              communication we use to notify of these changes. The most current
              version of our Terms and Conditions will always be posted on the
              Simmr website. By continuing to access or use Simmr after
              revisions become effective, you agree to be bound by the new
              Terms. If you don’t agree to the new Terms, please stop using
              Simmr.
            </li>
            <li className="max-w-lg">
              B. No waiver of any term of these Terms shall be deemed a further
              or continuing waiver of such term or any other term, and Simmr's
              failure to assert any right or provision under these Terms shall
              not constitute a waiver of such right or provision.
            </li>
            <li className="max-w-lg">
              C. These Terms, and any rights and licenses granted hereunder, may
              not be transferred or assigned by you, but may be assigned by
              Simmr without restriction. Any attempted transfer or assignment in
              violation hereof shall be null and void.
            </li>
            <li className="max-w-lg">
              D. These Terms, together with the Privacy Policy, Community
              Guidelines and any amendments and any additional agreements you
              may enter into with Simmr shall constitute the entire agreement
              between you and Simmr concerning the service. If any provision of
              these Terms is deemed invalid, then that provision will be limited
              or eliminated to the minimum extent necessary, and the remaining
              provisions of these Terms will remain in full force and effect.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </Layout>
)

export default TermsPage
